<template>
  <div class="home">
    <h1 class="title">MBTI One</h1>
    <p class="subtitle">다양한 심리 테스트를 경험해보세요</p>
    
    <div class="test-list">
      <div class="test-card" @click="goToTest('mbti')">
        <h2>MBTI 테스트</h2>
        <p>나의 성격 유형은 무엇일까요? 비교적 정확한 테스트를 위해 문항이 많아요!</p>
        <button class="start-button">시작하기</button>
      </div>
      
      <div class="test-card" @click="goToTest('adhd')">
        <h2>ADHD 테스트</h2>
        <p>주의력결핍 과잉행동장애 자가진단 테스트입니다!</p>
        <button class="start-button">시작하기</button>
      </div>

      <div class="test-card" @click="goToTest('iq')">
        <h2>[미완성] IQ 테스트</h2>
        <p>당신의 지능지수는 얼마일까요?</p>
        <button class="start-button">시작하기</button>
      </div>
    </div>

    <footer class="footer">
      <p>&copy; 2024 MBTI One. All rights reserved.</p>
      <p class="disclaimer">
        이 사이트에서 제공하는 MBTI 및 ADHD 검사는 오락적인 목적으로 설계되었습니다. 이 검사들은 공식적인 심리 평가 도구가 아니며, 과학적 근거가 부족할 수 있습니다. 따라서 검사 결과는 단지 참고용으로만 사용하시길 권장합니다. 중요한 결정을 내리기 위해서는 전문가의 조언을 구하는 것이 필요합니다. 이 사이트의 검사는 자신의 성격이나 상태를 간단히 알아보는 재미있는 방법일 뿐이므로, 과도하게 의존하지 않도록 주의해 주세요. 심리적 진단이나 치료가 필요하다면 반드시 전문적인 상담을 받으시기 바랍니다.
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  methods: {
    goToTest(testType) {
      if (testType === 'mbti') {
        this.$router.push('/mbti-test');
      } else if (testType === 'adhd') {
        this.$router.push('/adhd-test');
      } else if (testType === 'iq') {
        this.$router.push('/iq-test');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.test-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 60px;
}

.test-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 250px;
  flex: 0 1 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }
}

.start-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
}

.footer {
  margin-top: 40px;
  font-size: 0.9rem;

  .disclaimer {
    margin-top: 10px;
    font-size: 0.8rem;
  }
}
</style>