<template>
  <div class="saju-result" v-if="sajuData && sajuData.saju">
    <h2>{{ name }}님의 사주 결과</h2>
    
    <!-- 음양력 정보 -->
    <div class="date-info">
      <div class="solar">
        <h3>양력</h3>
        <p>{{ sajuData.saju.year }}년 {{ sajuData.saju.month }}월 {{ sajuData.saju.day }}일</p>
      </div>
      <div class="lunar">
        <h3>음력</h3>
        <p>{{ sajuData.lunarDate.year }}년 {{ sajuData.lunarDate.month }}월 {{ sajuData.lunarDate.day }}일</p>
      </div>
    </div>

    <!-- 사주 8자 -->
    <div class="saju-chart">
      <div class="chart-title">사주 팔자</div>
      <div class="chart-grid">
        <div v-for="(pillar, type) in sajuData.saju.pillars" 
             :key="type"
             class="chart-cell">
          <div class="cell-label">{{ getPillarLabel(type) }}</div>
          <div class="cell-content">
            <div class="heavenly">{{ pillar.stem }}</div>
            <div class="earthly">{{ pillar.branch }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 대운 -->
    <div class="major-destiny">
      <h3>대운</h3>
      <div class="destiny-grid">
        <div v-for="(destiny, index) in sajuData.destiny.major"
             :key="index"
             class="destiny-cell">
          <div class="age">{{ destiny.age }}세</div>
          <div class="stem-branch">{{ destiny.stem }}{{ destiny.branch }}</div>
        </div>
      </div>
    </div>

    <!-- 세운 -->
    <div class="yearly-destiny">
      <h3>세운</h3>
      <div class="destiny-grid">
        <div v-for="(yearly, index) in sajuData.destiny.yearly"
             :key="index"
             class="destiny-cell">
          <div class="year">{{ yearly.year }}</div>
          <div class="stem-branch">{{ yearly.stem }}{{ yearly.branch }}</div>
        </div>
      </div>
    </div>

    <!-- 육친 관계 -->
    <div class="six-relatives">
      <h3>육친 관계</h3>
      <div class="relatives-grid">
        <div v-for="(relative, index) in sajuData.relations"
             :key="index"
             class="relative-cell">
          {{ relative }}
        </div>
      </div>
    </div>

    <!-- 신살 -->
    <div class="godly-influences">
      <h3>신살</h3>
      <div class="influences-grid">
        <div v-for="(influence, index) in sajuData.influences"
             :key="index"
             class="influence-cell">
          {{ influence }}
        </div>
      </div>
    </div>

    <button class="reset-button" @click="$emit('reset')">
      다시 보기
    </button>
  </div>
  <div v-else>
    <p>사주 데이터를 불러오는 중입니다...</p>
  </div>
</template>
<script>
export default {
  name: 'SajuResult',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    sajuData() {
      return this.$store.state.saju || {};
    }
  },
  methods: {
    getPillarLabel(type) {
      const labels = {
        year: '년주',
        month: '월주',
        day: '일주',
        time: '시주'
      }
      return labels[type]
    }
  }
}
</script>

<style lang="scss" scoped>
.saju-result {
  padding: 2rem;
  
  h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: #2c3e50;
  }

  .destiny-grid, .relatives-grid, .influences-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
  }

  .destiny-cell, .relative-cell, .influence-cell {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
  }

  .date-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 2rem 0;
    text-align: center;
  }
}

.saju-chart {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  
  .chart-cell {
    text-align: center;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    
    .cell-label {
      font-size: 0.9rem;
      color: #666;
      margin-bottom: 0.5rem;
    }
    
    .heavenly {
      font-size: 1.5rem;
      color: #4a90e2;
      margin-bottom: 0.5rem;
    }
    
    .earthly {
      font-size: 1.5rem;
      color: #2c3e50;
    }
  }
}

.reset-button {
  display: block;
  margin: 2rem auto;
  padding: 12px 24px;
  background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
  }
}
</style>
