<template>
  <div class="saju-test">
    <h1>사주</h1>
    <div v-if="!testCompleted">
      <form @submit.prevent="calculateSaju">
        <label for="birthYear"><strong>출생일:</strong></label>

        <div class="birth-date">
          <input type="number" 
                 v-model="birthYear" 
                 required
                 :min="1900"
                 :max="new Date().getFullYear()"
                 placeholder="1990" />
          <label>년</label>

          <input type="number" 
                 v-model="birthMonth" 
                 required
                 min="1"
                 max="12"
                 placeholder="12" />
          <label>월</label>

          <input type="number" 
                 v-model="birthDay" 
                 required
                 min="1"
                 max="31"
                 placeholder="31" />
          <label>일</label>
        </div>

        <label for="birthTime"><strong>출생 시간:</strong></label>
        <div class="birth-time">
          <select v-model="birthHour" required>
            <option v-for="hour in 24" :key="hour-1" :value="hour-1">
              {{hour-1}}시
            </option>
          </select>
          <select v-model="birthMinute" required>
            <option v-for="minute in 60" :key="minute-1" :value="minute-1">
              {{minute-1}}분
            </option>
          </select>
        </div>

        <label for="gender"><strong>성별:</strong></label>
        <select v-model="gender" required>
          <option value="male">남성</option>
          <option value="female">여성</option>
        </select>

        <label for="name"><strong>이름:</strong></label>
        <input type="text" v-model="name" required placeholder="홍길동" />

        <button type="submit">사주 보기</button>
      </form>
    </div>
    
    <SajuResult 
      v-if="testCompleted"
      :name="name"
      :sajuData="sajuResult"
      @reset="resetForm"
    />
  </div>
</template>

<script>
import SajuResult from '@/components/SajuResult.vue'
import { calculateFullSaju } from '@/utils/sajuCalculator'

export default {
  name: 'SajuTest',
  components: {
    SajuResult
  },
  data() {
    return {
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      birthHour: 0,
      birthMinute: 0,
      gender: '',
      name: '',
      testCompleted: false,
      sajuResult: null
    }
  },
  methods: {
    validateDate() {
      const daysInMonth = new Date(this.birthYear, this.birthMonth, 0).getDate()
      if (this.birthDay > daysInMonth) {
        alert(`${this.birthMonth}월은 ${daysInMonth}일까지만 있습니다.`)
        return false
      }
      return true
    },
    
    calculateSaju() {
      if (!this.validateDate()) return

      const birthTime = `${this.birthHour}:${this.birthMinute}`
      this.sajuResult = calculateFullSaju(
        parseInt(this.birthYear),
        parseInt(this.birthMonth),
        parseInt(this.birthDay),
        birthTime,
        this.gender
      )
      
      this.testCompleted = true
    },
    resetForm() {
      this.testCompleted = false
      this.birthYear = ''
      this.birthMonth = ''
      this.birthDay = ''
      this.birthHour = 0
      this.birthMinute = 0
      this.gender = ''
      this.name = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.saju-test {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.birth-date, .birth-time {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  input, select {
    width: 80px;
    padding: 8px;
    border: 2px solid #e0e0e0;
    border-radius: 6px;
    text-align: center;
    
    &:focus {
      border-color: #4a90e2;
      outline: none;
    }
  }
}

select, input[type="text"] {
  padding: 8px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  background: white;
  
  &:focus {
    border-color: #4a90e2;
    outline: none;
  }
}

button {
  padding: 12px 24px;
  background: linear-gradient(135deg, #4a90e2 0%, #357abd 100%);
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
  }
}
</style>