// 천간, 지지, 육십갑자 정의
const HEAVENLY_STEMS = ['甲', '乙', '丙', '丁', '戊', '己', '庚', '辛', '壬', '癸']
const EARTHLY_BRANCHES = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥']
const SIXTY_JIAZI = [
  "甲子", "乙丑", "丙寅", "丁卯", "戊辰", "己巳", "庚午", "辛未", "壬申", "癸酉",
  "甲戌", "乙亥", "丙子", "丁丑", "戊寅", "己卯", "庚辰", "辛巳", "壬午", "癸未",
  "甲申", "乙酉", "丙戌", "丁亥", "戊子", "己丑", "庚寅", "辛卯", "壬辰", "癸巳",
  "甲午", "乙未", "丙申", "丁酉", "戊戌", "己亥", "庚子", "辛丑", "壬寅", "癸卯",
  "甲辰", "乙巳", "丙午", "丁未", "戊申", "己酉", "庚戌", "辛亥", "壬子", "癸丑",
  "甲寅", "乙卯", "丙辰", "丁巳", "戊午", "己未", "庚申", "辛酉", "壬戌", "癸亥"
]

// 24절기 정의
const SOLAR_TERMS = {
  "입춘": { month: 2, day: 4 },
  "우수": { month: 2, day: 19 },
  "경칩": { month: 3, day: 6 },
  "춘분": { month: 3, day: 21 },
  "청명": { month: 4, day: 5 },
  "곡우": { month: 4, day: 20 },
  "입하": { month: 5, day: 6 },
  "소만": { month: 5, day: 21 },
  "망종": { month: 6, day: 6 },
  "하지": { month: 6, day: 21 },
  "소서": { month: 7, day: 7 },
  "대서": { month: 7, day: 23 },
  "입추": { month: 8, day: 8 },
  "처서": { month: 8, day: 23 },
  "백로": { month: 9, day: 8 },
  "추분": { month: 9, day: 23 },
  "한로": { month: 10, day: 8 },
  "상강": { month: 10, day: 24 },
  "입동": { month: 11, day: 7 },
  "소설": { month: 11, day: 22 },
  "대설": { month: 12, day: 7 },
  "동지": { month: 12, day: 22 },
  "소한": { month: 1, day: 6 },
  "대한": { month: 1, day: 20 }
}

// 오행 관계
const FIVE_ELEMENTS = {
  "목": { generates: "화", controls: "토", color: "청색" },
  "화": { generates: "토", controls: "금", color: "적색" },
  "토": { generates: "금", controls: "수", color: "황색" },
  "금": { generates: "수", controls: "목", color: "백색" },
  "수": { generates: "목", controls: "화", color: "흑색" }
}

// 천간 오행
const STEM_ELEMENTS = {
  "甲": "목", "乙": "목",
  "丙": "화", "丁": "화",
  "戊": "토", "己": "토",
  "庚": "금", "辛": "금",
  "壬": "수", "癸": "수"
}

// 지지 오행
const BRANCH_ELEMENTS = {
  "子": "수", "丑": "토", "寅": "목",
  "卯": "목", "辰": "토", "巳": "화",
  "午": "화", "未": "토", "申": "금",
  "酉": "금", "戌": "토", "亥": "수"
}

// 시간 지지 매핑
const TIME_TO_BRANCH = {
  "23:00-01:00": "子", "01:00-03:00": "丑", "03:00-05:00": "寅",
  "05:00-07:00": "卯", "07:00-09:00": "辰", "09:00-11:00": "巳",
  "11:00-13:00": "午", "13:00-15:00": "未", "15:00-17:00": "申",
  "17:00-19:00": "酉", "19:00-21:00": "戌", "21:00-23:00": "亥"
}

export function calculateSaju(birthYear, birthMonth, birthDay, birthTime) {
  // 년주 계산
  const yearIndex = (birthYear - 4) % 60
  const yearStem = SIXTY_JIAZI[yearIndex].charAt(0)
  const yearBranch = SIXTY_JIAZI[yearIndex].charAt(1)
  const yearElement = BRANCH_ELEMENTS[yearBranch]

  // 시주 계산에 TIME_TO_BRANCH 활용
  const timeData = calculateTimePillar(yearStem, birthTime)
  
  // 월주 계산 (절기 반영)
  const monthData = calculateMonthPillar(birthYear, birthMonth, birthDay)
  
  // 일주 계산
  const dayData = calculateDayPillar(birthYear, birthMonth, birthDay)

  return {
    pillars: {
      year: { stem: yearStem, branch: yearBranch, element: yearElement },
      month: monthData,
      day: dayData,
      time: timeData
    },
    elements: analyzeElements(yearStem, monthData.stem, dayData.stem, timeData.stem)
  }
}

function calculateMonthPillar(year, month, day) {
  const currentSolarTerm = findSolarTerm(month, day)
  const monthBranch = EARTHLY_BRANCHES[((month + 1) % 12)]
  const stemIndex = (year * 2 + (month + 1)) % 10
  
  return {
    stem: HEAVENLY_STEMS[stemIndex],
    branch: monthBranch,
    solarTerm: currentSolarTerm
  }
}
function findSolarTerm(month, day) {
  return Object.entries(SOLAR_TERMS)
    .find(([, date]) => date.month === month && date.day === day)?.[0]
}

function calculateDayPillar(year, month, day) {
  const baseDate = new Date(1900, 0, 1)
  const birthDate = new Date(year, month - 1, day)
  const daysDiff = Math.floor((birthDate - baseDate) / (1000 * 60 * 60 * 24))
const stemIndex = (daysDiff + 10) % 10
const branchIndex = (daysDiff + 12) % 12
return {
  stem: HEAVENLY_STEMS[stemIndex],
  branch: EARTHLY_BRANCHES[branchIndex]
}
}

function calculateTimePillar(dayStem, time) {
  const hour = parseInt(time.split(':')[0]);
  let branch = null;

  for (const [timeRange, branchValue] of Object.entries(TIME_TO_BRANCH)) {
      const [start, end] = timeRange.split('-');
      const startHour = parseInt(start);
      const endHour = parseInt(end);
      if ((hour >= startHour && hour < endHour) || (endHour < startHour && (hour >= startHour || hour < endHour))) {
        branch = branchValue;
        break;
      }
  }

  return {
      stem: HEAVENLY_STEMS[(HEAVENLY_STEMS.indexOf(dayStem) * 2) % 10],
      branch: branch,
  };
}

function analyzeElements(yearStem, monthStem, dayStem, timeStem) {
  const elements = { 목: 0, 화: 0, 토: 0, 금: 0, 수: 0 };
  [yearStem, monthStem, dayStem, timeStem].forEach(stem => {
      const element = STEM_ELEMENTS[stem];
      elements[element]++;
  });

  const relationships = Object.keys(elements).map(element => {
      return {
          element,
          generates: FIVE_ELEMENTS[element]?.generates,
          controls: FIVE_ELEMENTS[element]?.controls,
      };
  });

  return { counts: elements, relationships };
}

function calculateMajorDestiny(birthYear, gender, saju) {
  const destinyPillars = [];
  const startAge = gender === 'male' ? 1 : 10;
    
  // 실제 대운 계산 로직 구현
  for(let i = 0; i < 8; i++) {
      destinyPillars.push({
          age: startAge + (i * 10),
          year: birthYear + startAge + (i * 10),
          stem: saju.month.stem,
          branch: saju.month.branch
      });
  }
  return destinyPillars;
}

export function calculateFullSaju(birthYear, birthMonth, birthDay, birthTime, gender) {
  const saju = calculateSaju(birthYear, birthMonth, birthDay, birthTime)
  const majorDestiny = calculateMajorDestiny(birthYear, gender, {
    month: {
      stem: saju.pillars.month.stem,
      branch: saju.pillars.month.branch
    }
  })
  
  return {
    ...saju,
    destiny: majorDestiny
  }
}